export const initPageHeader = (headerEl) => {
  // set up mobile main nav toggle
  const menuToggle = headerEl?.querySelector('[data-toggle="mobile-nav"]');

  menuToggle?.addEventListener('click', (e) => {
    e.preventDefault();

    const html = document.documentElement;
    const mainNav = document.getElementById('main-nav');

    menuToggle?.classList?.toggle('is-open');
    html?.classList?.toggle('noscroll');
    mainNav?.classList?.toggle('is-open');
  });

  // set up search dropdown toggle
  const searchDropdownToggles = document.querySelectorAll('[data-toggle="search-dropdown"]');
  searchDropdownToggles.forEach((toggle) => {
    toggle.addEventListener('click', (e) => {
      e.preventDefault();
      document.getElementById('search-dropdown')?.classList.toggle('is-open');
      document.getElementById('browse-dropdown')?.classList.remove('is-open');
    });
  });

  // set up browse dropdown toggle
  const browseDropdownToggles = document.querySelectorAll('[data-toggle="browse-dropdown"]');
  browseDropdownToggles.forEach((toggle) => {
    toggle.addEventListener('click', (e) => {
      e.preventDefault();
      document.getElementById('browse-dropdown')?.classList.toggle('is-open');
      document.getElementById('search-dropdown')?.classList.remove('is-open');
    });
  });

  // set up browse submenu toggle
  const browseSubmenuToggles = document.querySelectorAll('[data-toggle-browse]');
  browseSubmenuToggles.forEach((toggle) => {
    toggle.addEventListener('click', (e) => {
      e.preventDefault();
      const submenuId = toggle.dataset.toggleBrowse;
      document.getElementById(submenuId)?.classList.toggle('is-open');

      const html = document.documentElement;
      html?.classList?.toggle('noscroll');
    });
  });

};
