const shareAdSelectEl = document.getElementById('share-ad-select');

if(shareAdSelectEl) {
  initShareAdSelect();
}

function initShareAdSelect() {
  const checkboxes = shareAdSelectEl.querySelectorAll('.control-checkbox');
  for(let checkbox of checkboxes) {
    checkbox.addEventListener('change', (e) => onChangeCheckbox(e.target));
  }
}

function onChangeCheckbox(checkbox) {
  const dataName = checkbox.dataset.groupName || false;
  const isChecked = checkbox.checked;

  if(dataName && isChecked) {
    checkChildren(dataName);
  }

  if(dataName && !isChecked) {
    uncheckChildren(dataName);
  }

  if(isChecked) {
    checkParent(checkbox.dataset.name);
  }
  
  if(!isChecked) {
    uncheckParent(checkbox.dataset.name);
  }
}

function checkChildren(name) {
  const children = shareAdSelectEl.querySelectorAll(`[data-name="${name}"]`);
  for(let checkbox of children) {
    const dataName = checkbox.dataset.groupName || false;
    checkbox.checked = true;
    if(dataName) {
      checkChildren(dataName)
    }
  }
}

function uncheckChildren(name) {
  const children = shareAdSelectEl.querySelectorAll(`[data-name="${name}"]`);
  if(!Array.from(children).find((child) => !child.checked)) {
    for(let checkbox of children) {
      const dataName = checkbox.dataset.groupName || false;
      checkbox.checked = false;
      if(dataName) {
        uncheckChildren(dataName)
      }
    }
  }
}

function checkParent(name) {
  const parent = shareAdSelectEl.querySelector(`[data-group-name="${name}"]`);
  if(parent) {
    const children = shareAdSelectEl.querySelectorAll(`[data-name="${name}"]`);
    if(!Array.from(children).find((child) => !child.checked)) {
      parent.checked = true;
      checkParent(parent.dataset.name);
    }
  }
}

function uncheckParent(name) {
  const parent = shareAdSelectEl.querySelector(`[data-group-name="${name}"]`);
  if(parent) {
    parent.checked = false;
    uncheckParent(parent.dataset.name);
  }
}