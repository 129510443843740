import * as $ from 'jquery';

export default function initSettings() {
    if(document.getElementById('user-settings') || document.getElementById('share-ad-name')) {
        console.log('load fmm');
        initFMM();
        initLanguage();
    }
}

function initFMM() {
    let add_button = document.getElementById('add_custom_fmm');
    let remove_button = document.getElementById('remove_custom_fmm');
    let custom_form = document.getElementById('custom_fmm_form');
    let search_form = document.getElementById('search_fmm_form');
    
    add_button?.addEventListener('click', onAddCustomFMM);
    
    remove_button?.addEventListener('click', onRemoveCustomFMM);

    if (document.getElementById('custom_fmm_name')?.value?.length) {
        onAddCustomFMM();
    }


    $('[data-aircomplete=""]').aircomplete({
        ajaxOptions: {
            url: '/field-advertising/api-fmm-list',
            dataType: 'json', // or jsonp
            method: 'GET'
        },
        //dataKey: 'name',
        template: function(element, searchTerm) {
            var item = "";

            item += "<div>";
            item += "  " + element.name;
            item += "</div>";

            return item;
        },
        onSelect: function(data) {
            $('#fmm-user-email').val(data.userid);
            return data.name;
        },
        onChange: function(data) {
            $('#fmm-user-email').val("");
        },
        onBlur: function() {
            if ($('#fmm-user-email').val() == "") {
                $('[data-aircomplete="fmm-users"]').val("");
            }
        },
        match: function(dataRow, searchTerm) {
            return dataRow.name.toLowerCase().indexOf(searchTerm.toLowerCase()) > -1;
        },
        searchDelay: 500,
        minSearchStringLength: 1 // show results after a single character is entered
    });
    
    function onAddCustomFMM(e) {
        e?.preventDefault();

        custom_form.style.display = 'block';
        search_form.style.display = 'none';
        
        if(document.getElementById('fmm-user-email')) {
            document.getElementById('fmm-user-email').value = '';
        }
    }
    
    function onRemoveCustomFMM(e) {
        e?.preventDefault();
        
        custom_form.style.display = 'none';
        search_form.style.display = 'block';
        
        if(document.getElementById('custom_fmm_name')) {
            document.getElementById('custom_fmm_name').value = '';
        }
        
        if(document.getElementById('custom_fmm_email')) {
            document.getElementById('custom_fmm_email').value = '';
        }
    }
}

function initLanguage() {
    const all_check = document.getElementById('all_check');
    
    if(!all_check) {
        return;
    }
    
    showBoxes(!all_check.checked);
    
    all_check.addEventListener('input', (e) => {
        showBoxes(!e.target.checked);
    });
    
    function showBoxes(show = true) {
        const langauge_boxes = document.querySelectorAll('[data-language]');
        
        langauge_boxes.forEach((box) => {
            box.style.display = show ? 'block' : 'none';
        });
    }
}