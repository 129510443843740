import './lib/jquery.aircomplete.js';
import { initFavoritesToggle } from './components/atd-favorites-toggle.js';
import { initDefaultGlide } from './components/slider';
import Tabset from './components/tabset';
import initTabsNavs from './components/tabsNav';
import { setupModalToggles } from './components/modal.js';
import initSettings from './components/settings';
import { initExpandsets } from './components/expandset';
import { initUserGroupApp } from './apps/user-group-vue.js';
import { initDashboardPromosApp } from './apps/dashboard-promos.js';
import { initAdvancedSearchApp } from './apps/advanced-search.js';
import datepicker from 'js-datepicker';
import './components/promo-popup.js';
import {activateSortable} from './lib/SortForm.js';
import './components/share-ad-select.js';
import { initPageHeader } from './components/page-header.js';

activateSortable();

// init page header 
initPageHeader(document.getElementById('page-header'));

// init glide carsouel
initDefaultGlide();

// init tabNavs
initTabsNavs();

// init tabsets
const tabsets = document.querySelectorAll('[data-tabset]');
tabsets.forEach((tabset) => {
    new Tabset(tabset);
});

// init settings js
initSettings();

// init expandsets
initExpandsets();

// init user group settings app
initUserGroupApp();

// init dashboard promos app
initDashboardPromosApp();

// init advanced search app
initAdvancedSearchApp();

// init favorites heart
initFavoritesToggle();

// init datepicker
if(document.querySelector('[data-date-picker]')) {
    datepicker('[data-date-picker]');
}

//init modals
let modals = document.querySelectorAll('[data-modal]');
modals.forEach((modal) => {
    setupModalToggles(modal.id);
});
